import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Monitors } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/monitors`,
    method: HTTP_METHODS.GET,
    response: {} as Monitors.Get.V2.Monitors,
  },
};
