import { HTTP_METHODS } from '@studio/constants/axios';
import { HOTSPOTS_API_V1 } from '@studio/constants/env-variables';
import type { Hotspots } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${HOTSPOTS_API_V1}/customers/@customerId/urls`,
    response: [] as Hotspots.Get.Url[],
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${HOTSPOTS_API_V1}/customers/@customerId/urls`,
    request: {} as Hotspots.Post.Hotspot,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${HOTSPOTS_API_V1}/customers/@customerId/urls/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${HOTSPOTS_API_V1}/customers/@customerId/urls/@id`,
    response: {} as Hotspots.Get.Hotspot,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PUT]: {
    url: `${HOTSPOTS_API_V1}/customers/@customerId/urls/@id`,
    request: {} as Hotspots.Put.Hotspot,
    params: {} as { id: string },
    method: HTTP_METHODS.PUT,
  },
};
