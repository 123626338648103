import { customers } from './customers';
import { encoderProfiles } from './encoder-profiles';
import { encoders } from './encoders';
import { monitors } from './monitors';
import { plans } from './plans';
import { streamProfiles } from './stream-profiles';
import { userPlans } from './user-plans';
import { users } from './users';
import { webPlans } from './web-plans';

export const v2 = {
  customers,
  encoders,
  encoderProfiles,
  monitors,
  plans,
  streamProfiles,
  userPlans,
  users,
  webPlans,
};
